<template>
  <div class="recruitDetails">
    <TopNavBar />
    <div class="box">
      <el-breadcrumb separator=">>">
        <el-breadcrumb-item :to="{ path: '/recruit' }">招聘</el-breadcrumb-item>
        <el-breadcrumb-item>招聘详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="recruit_table">
        <div class="title_box">
          <div>{{ data.name }}</div>
          <el-button type="text" @click="deliver">投递简历</el-button>
        </div>
        <div class="info_box">
          <template v-for="(item, index) in list">
            <div :key="item.key" class="item" v-if="index < 3">
              {{ item.title }}:<span>{{ data[item.key] }}</span>
            </div>
          </template>
        </div>
        <div class="info_box">
          <template v-for="(item, index) in list">
            <div :key="item.key" class="item" v-if="index >= 3">
              {{ item.title }}:<span>{{ data[item.key] }}</span>
            </div>
          </template>
        </div>
      </div>
      <div class="describe_box">
        <div>
          <h3>职位描述：</h3>
          <p>{{ data.jobDescription }}</p>
        </div>
        <div>
          <h3>岗位要求：</h3>
          <p v-html="data.jobRequirements"></p>
        </div>
      </div>
    </div>
    <Footer />
    <el-upload
      class="upload-resource"
      accept=".doc, .docx, .ppt, .pptx"
      :action="upload.url"
      :disabled="upload.isUploading"
      :on-progress="handleFileUploadProgress"
      :on-success="handleFileSuccess"
      :limit="1"
    ></el-upload>
  </div>
</template>

<script>
import { add } from '@/api/index'
export default {
  name: "RecruitDetails",
  data() {
    return {
      data: {
        name: this.$route.query.title,
        price: this.$route.query.salaryRange,
        experience: this.$route.query.workExperience,
        education: this.$route.query.education,
        number: this.$route.query.recruitingNumbers,
        workLocation: this.$route.query.workLocation,
        releaseTime: this.$route.query.releaseTime,

        jobDescription: this.$route.query.jobDescription,
        jobRequirements: this.$route.query.jobRequirements,
      },
      upload: {
        // 上传的地址
        url: process.env.VUE_APP_API_URL + "/common/deliverUploadFile",
        isUploading: false,
      },
      list: [
        {
          title: "工作经验",
          key: "experience",
        },
        {
          title: "学历要求",
          key: "education",
        },
        {
          title: "薪酬范围",
          key: "price",
        },
        {
          title: "工作地点",
          key: "workLocation",
        },
        {
          title: "招聘人数",
          key: "number",
        },
        {
          title: "发布时间",
          key: "releaseTime",
        },
      ],
    };
  },
  created() {
    // this.data.dates = this.data.date.split("-");
  },
  methods: {
    // 文件上传时
    handleFileUploadProgress() {
      this.upload.isUploading = true;
    },
    // 文件上传成功
    async handleFileSuccess(response, file, fileList) {
      this.upload.isUploading = false;
      const enclosure = response.url
      const res = await add({
        positionName: this.data.name,
        enclosure:enclosure
      })
      if (res.code == 200) {
        this.$message({
          message: '上传简历成功',
          type: 'success'
        });
      }else{
        this.$message.error('上传简历失败');
      }
    },
    // 点击投递简历
    deliver(){
      document.querySelector(".upload-resource .el-upload").click()
    }
  },
};
</script>

<style scoped lang="scss">
.recruitDetails {
  .box {
    padding: 70px 100px 60px;
    ::v-deep .el-breadcrumb {
      .el-breadcrumb__item {
        .el-breadcrumb__inner,
        .el-breadcrumb__separator {
          font-size: 20px;
          font-weight: 200;
          line-height: 90px;
          color: #333;
        }
        .el-breadcrumb__separator {
          font-family: "AlibabaPuHuiTi-Light";
          margin: 0 12px;
        }
      }
    }
    .recruit_table {
      width: 100%;
      border-top: 1px solid #e4e4e4;
      border-left: 1px solid #e4e4e4;
      .title_box {
        height: 100px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
        border-right: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;
        div {
          font-weight: 400;
          font-size: 25px;
          color: #000000;
        }
        .el-button {
          font-weight: 400;
          font-size: 20px;
          color: #006eff;
        }
      }
      .info_box {
        display: flex;
        justify-content: space-between;
        .item {
          flex: 1;
          line-height: 80px;
          font-size: 20px;
          color: #000000;
          border-right: 1px solid #e4e4e4;
          border-bottom: 1px solid #e4e4e4;
          text-align: left;
          padding-left: 45px;
          font-family: "AlibabaPuHuiTi-Regular";
          font-weight: 400;
          span {
            font-weight: 200;
            font-family: "AlibabaPuHuiTi-Light";
            margin-left: 10px;
          }
        }
      }
    }
    .describe_box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 45px;
      margin-bottom: 30px;
      > div {
        h3 {
          font-weight: 400;
          font-size: 20px;
          color: #333333;
          text-align: left;
          line-height: 26px;
          margin: 30px 0 25px;
        }
        p {
          font-weight: 200;
          font-size: 20px;
          color: #666666;
          line-height: 40px;
          text-align: left;
          font-family: "AlibabaPuHuiTi-Light";
        }
      }
    }
  }
}
</style>
